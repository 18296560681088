
.icon-wrap{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: var(--v-primary-base);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon--responsive{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
}
.icon--responsive__inner{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .divider--end{
        position: relative;
    }
    .divider--end::after{
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: calc(100% - 40px);
        top: 20px;
        right: 0;
        background-color: var(--border-color);
    }
}
@media (min-width:1024px){
    .process-wrap .row{
        margin: -7px;
    }
    .process-wrap .row>[class*="col"]{
        padding: 7px;
    }
    .divider--end::after{
        height: calc(100% - 100px);
        top: 50px;
    }
}
@media (min-width:1200px){
    .process-wrap{
        position: relative;
    }
    .process-wrap::before{
        content: "";
        display: block;
        position: absolute;
        width: calc(100% + 14px);
        height: 24px;
        left: 50%;
        top: calc(50% - 49px);
        transform: translate(-50%,-50%);
        background-image: url(/images/sub/support/legacy/process-arrow.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}

