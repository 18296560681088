
.row-image:not(:last-child){
    margin-bottom: 24px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .row-image__info{
        max-width: calc(100% - 310px);
    }
}
@media (min-width:1200px){
}
